import React, { useState} from 'react';

import Modal from '../../../../common/Modal';


export function ImageCell({item, viewType}){
  const [zoomUrlModal, setZoomUrlModal] = useState('');

  return (
    <>
      <div
        className='image'
        style={ { backgroundImage: `url(/image/${item.articleSearch})`, cursor: 'pointer' } }
        onClick={() => setZoomUrlModal(item.articleSearch)}
      />
  
      {viewType === 'variant2' && (
        <div className='attrRow'>
          <span>Наименование:</span>
          {item.name}
        </div>
      )}
  
      {zoomUrlModal &&
        <Modal
          open
          title={''}
          className="zoomImageModal"
          content={
            <div style={{ width: '800px'}}>
              <img width={'100%'} src={`/image/${item.articleSearch}`} />
            </div>
          }
          onClose={() => setZoomUrlModal('')}
        />
      }
    </>);
}