const FETCH_ORDERS = {
    REQUEST: 'FETCH_ORDERS_REQUEST',
    SUCCESS: 'FETCH_ORDERS_SUCCESS',
    FAILURE: 'FETCH_ORDERS_FAILURE',
};

export function fetchSpareparts(query) {
    return {
        types: [FETCH_ORDERS.REQUEST, FETCH_ORDERS.SUCCESS, FETCH_ORDERS.FAILURE],
        payload: {
            request: {
                url: `/sparepart/${query}`,
            },
        },
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_ORDERS.REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_ORDERS.SUCCESS:
            return {
                ...state,
                result: action.payload.data,
                isFetching: false,
            };
        case FETCH_ORDERS.FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default: {
            return state;
        }
    }
}
