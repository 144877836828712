const VERIFY_CODE = {
    REQUEST: 'VERIFY_CODE_REQUEST',
    SUCCESS: 'VERIFY_CODE_SUCCESS',
    FAILURE: 'VERIFY_CODE_FAILURE',
};

export function verifyCode(code) {
    return {
        types: [VERIFY_CODE.REQUEST, VERIFY_CODE.SUCCESS, VERIFY_CODE.FAILURE],
        payload: {
            request: {
                url: `/certcode/${code}`,
            },
        },
    };
}
