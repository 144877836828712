import ConnectReducers from '../../helpers/ConnectReducers';
import { reducer as featchOrders } from './ducks/fetchOrders';
// import { reducer as addRecord } from './ducks/addRecord';

const initialState = {
    list: [],
    isFetching: true,
};

export default ConnectReducers([featchOrders], initialState);
