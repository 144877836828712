import ConnectReducers from '../../helpers/ConnectReducers';
import { reducer as fetchBasket } from './ducks/fetchBasket';
import { reducer as updateBasket } from './ducks/updateBasket';
import { reducer as removeFromBasket } from './ducks/removeFromBasket';
import { reducer as clearBasket } from './ducks/clearBasket';

const initialState = {
    list: [],
    isFetching: true,
};

export default ConnectReducers([fetchBasket, updateBasket, removeFromBasket, clearBasket], initialState);
