import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loginUser } from '../../../../features/Main/ducks/loginUser';
import Modal from '../../../Modal';

import './styles.scss';


const AuthModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToRequest = useCallback(() => { onClose(); history.push(`/?r=${Math.random()}#request`); }, []);

  const [error, setError] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const changeLoginHandler = useCallback((e) => setLogin(e.target.value), []);
  const changePasswordHandler = useCallback((e) => setPassword(e.target.value), []);
  
  const loginHandler = useCallback((e) => {
    if(!login || !password) {
      setError('На форме есть не заполненные поля');
      return;
    }
    dispatch(loginUser({ email: login, password }))
      .then(res => {
        const { payload } = res;
        sessionStorage.token = payload.data.token;
        // eslint-disable-next-line no-restricted-globals
        location.href='/';
      })
      .catch(() => {
        setError('Не верный логин или пароль');
      });
  }, [dispatch, login, password]);

  return (
    <Modal
      className="authModal"
      open={open}
      onClose={onClose}
      title={
      <div className='title'>
        <div className='highlight'>Вход</div>
        <div>в личный кабинет</div>
      </div>
      }
      content={
      <>
        <div className='content form'>
            <div className='field'>
              <div className='label'>Логин</div>
              <input className={error && !login ? 'error' : ''} type='text' placeholder='Введите ваш зарегистрированный логин' value={login} onChange={changeLoginHandler}></input>
            </div>
            <div className='field'>
              <div className='label'>Пароль</div>
              <input className={error && !password ? 'error' : ''} type='password' placeholder='Введите ваш пароль' value={password} onChange={changePasswordHandler}></input>
            </div>
            {error && <div className="highlight">{error}</div>}
            <button className="default" onClick={loginHandler}>Войти</button>
          </div>
          
          <div className="rememberLink">Забыли пароль? Обратитесь к персональному менеджеру</div>

          <div>
            <div>Для получения пароля необходимо отправить заявку</div>
            <button className="default" onClick={goToRequest}>Отправить заявку</button>
          </div>
      </>
    }
  />);
};

export default AuthModal;


