import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sumBy } from 'lodash';

import Modal from '../../common/Modal';
import HeaderLine from '../../common/HeaderLine';
import Selector from './selectors';
import { fetchBasket, sendOrder, removeFromBasket, clearBasket } from './actions';
import Sparepart from '../Search/components/Sparepart';
import { SparepartModel } from '../Search/models';

import './styles.scss';


export const Basket = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const items: SparepartModel[] = useSelector(state => Selector(state, 'basket'));
  const [note, setNote] = useState('');
  const [delivery, setDelivery] = useState('');
  const [resultModal, setResultModal] = useState<{ isShow: boolean; success?: boolean; message?: string }>({ isShow: false, success: false, message: ''});
  const [acceptedItems, setAcceptedItems] = useState<number[]>([]);

  const changeDeliveryHandler = useCallback((e) => setDelivery(e.target.value), []);

  const changeNoteHandler = useCallback((e) => setNote(e.target.value), []);

  const backToSearch = useCallback(() => history.push('/search'), [history]);

  const goToArchive = useCallback(() => history.push('/orders'), [history]);

  const sendOrderHandler = useCallback(() => {
    if(!acceptedItems.length) return;
    (dispatch(sendOrder({ items: items.filter(i => acceptedItems.includes(i.id)), note, delivery })) as unknown as Promise<{}>)
      .then(() => {
        setResultModal({ isShow: true, success: true, message: 'Ваш заказ успешно принят!' });
      })
      .catch(() => setResultModal({ isShow: true, success: false, message: 'Ошибка сохранения заказа, пожалуйста попробуйте позднее, или свяжитесь с вашим персональным менеджером'}));
  }, [acceptedItems, dispatch, items, note, delivery]);

  const onAccept = useCallback((item: SparepartModel, direction: boolean) => {
    direction
      ? setAcceptedItems([item.id, ...acceptedItems.filter(i => i !== item.id)])
      : setAcceptedItems(acceptedItems.filter(i => i !== item.id));
  }, [acceptedItems]);
  const onDelete = useCallback((item: SparepartModel) => dispatch(removeFromBasket(item)), [dispatch]);

  useEffect(() => {
    dispatch(fetchBasket());
  }, [dispatch]);

  return (
    <div className="mainContainer relative basket">
      <HeaderLine redline />
      {
        items.map((item: SparepartModel) =>
          <Sparepart
            key={item.id}
            item={item}
            accepted={acceptedItems.includes(item.id)}
            goToArchive={goToArchive}
            onAccept={onAccept}
            onDelete={onDelete}
          />)
      }
      <div className="order">
        <div>
          Количество позиций: <span className='highlight'>{sumBy(items, i => Number(i.count))}</span>
        </div>
        <div>
          Подтвержденных позиций: <span className='highlight'>{sumBy(items.filter(i => acceptedItems.includes(i.id)), (item: SparepartModel) =>Number(item.count))}</span>
        </div>
        <div>
          <strong>ОБЩАЯ СУММА ЗАКАЗА</strong>
          <span className='amount'>
            {sumBy(items.filter(i => acceptedItems.includes(i.id)), (item: SparepartModel) => Number(item.price) * Number(item.count))}
          </span>
          <span>руб.</span>
        </div>
      </div>

      <div className='form'>
        <div className="x4">
          <label className="label">Спопсоб доставки</label>
          <select value={delivery} onChange={changeDeliveryHandler}>
            <option>Самовывоз</option>
          </select>
        </div>
        <div className="x6">
          <label className="label">Комментарий</label>
          <textarea onChange={changeNoteHandler} value={note} />
        </div>
      </div>
      <div className='controls'>
        <div className="x4">
          <button className='accent' onClick={backToSearch}>ВУРНУТЬСЯ В ПОИСК</button>
        </div>
        <div className="x6">
          <button className='accent' disabled={!acceptedItems.length} onClick={sendOrderHandler}>ОФОРМИТЬ ЗАКАЗ</button>
        </div>
      </div>
      {resultModal.isShow &&
        <Modal
          open
          title={'Результат обработки'}
          content={resultModal.message}
          onClose={() => {
            resultModal.success && dispatch(clearBasket());
            setResultModal({ isShow: false });
          }}
        />
      }
    </div>
  );
};



