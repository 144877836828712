import React from 'react';
import HeaderLine from '../../../../common/HeaderLine';
import './styles.scss';

const About = () => (
  <div className="aboutMainBlock" id='about'>
    <HeaderLine type='h2' redline />
    <h2>О компании</h2>
    <div className="grid">
      <div className="grid--span-3">
        <div className='poster'></div>
      </div>
      <div className="grid--span-9">
        <p >
          MOUSSON.Parts представлен прямым дистрибьютором торговой марки MOUSSON в России. В ассортимент дистрибьюции входят: комплекты стандартных и ремонтных поршней ДВС, кольца поршневые, прокладки ДВС, комплекты цепи ГРМ, гильзы ДВС, коленчатые и распределительные валы, а также многие другие детали ДВС. Мы импортируем детали двигателей от заводов-производителей, и Вы можете найти оригинальные детали MOUSSON в наличии на складе. Подбор деталей двигателей осуществляется как по ОЕМ, так и по артикулу производителя. Наши клиенты знают нас как лучший сервис по организации клиентского обслуживания в оптовой торговле автозапчастями.
        </p>
        <p>
          Когда Вы покупаете автозапчасти на MOUSSON.Parts, можете быть уверены, что получите автозапчасти самого высокого качества по оптовым ценам, а все детали, которые мы продаем, поставляются с полной гарантией. Мы также понимаем, как важно получить правильные детали, которые нужны, когда Вы покупаете автозапчасти онлайн. Вот почему все наши детали поставляются с нашим обещанием гарантированного точного соответствия оригинальным запчастям производителя автомобилей. При возникновении любых претензий со стороны покупателей мы рассматриваем доставку туда и обратно за наш счет. В ассортимент деталей MOUSSON, поставляемых в Россию, входят детали двигателей на модели автомобилей, как официально поставляемых на рынок России производителями автомобилей, так и на наиболее популярные модели, ввезенные с других рынков. В ассортименте представлены детали для двигателей Audi, BMW, Honda, Hyundai, KIA, Land Rover, Mercedes Benz, Toyota, Volkswagen, Porsche.
        </p>
        <p>
          Если не можете найти то, что Вам необходимо, пожалуйста, не стесняйтесь позвонить нам сегодня и поговорить с нами в любое время 7 дней в неделю! Мы всегда рады помочь Вам найти нужные детали: +7(495)122-22-92.
        </p>
      </div>
    </div>
  </div>
);

export default About;