const SEND_ORDER = {
    REQUEST: 'SEND_ORDER_REQUEST',
    SUCCESS: 'SEND_ORDER_SUCCESS',
    FAILURE: 'SEND_ORDER_FAILURE',
};

export function sendOrder(data) {
    return {
        types: [SEND_ORDER.REQUEST, SEND_ORDER.SUCCESS, SEND_ORDER.FAILURE],
        payload: {
            request: {
                method: 'post',
                url: '/order',
                data,
            },
        },
    };
}