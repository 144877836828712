import { get } from 'lodash';

type SelectorKeys = 'spareparts' | 'isFetching';

export default function Selector(state: any, property: SelectorKeys) {
    const selectors = {
        spareparts: (s: any) => {
            const spareparts = s.search?.result;
            const res = [spareparts.origin, ...(spareparts.crosses || []), ...(spareparts.origins || [])]
                .filter(i => i)
                .map(item => ({ ...item, price: get(item, 'price[0].price', 0), available: get(item, 'available[0]', null) }))
                .sort((a, b) => a.available && b.available ? b.available.available - a.available.available : -1);
            return res;
        },
        isFetching: (s: any) => s.search?.isFetching || false,
        related: (s: any) => {
            const spareparts = s.search?.result || {};
            return spareparts.related || [];
        }
    };
    return selectors[property](state);
}
