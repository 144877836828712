import { useState, useCallback } from 'react';

type UseBooleanFlag = (
  defaultValue?: boolean,
) => {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
};

export const useBooleanFlag: UseBooleanFlag = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(!value), [value]);

  return {
    value,
    setTrue,
    setFalse,
    toggle,
  };
};
