const FETCH_NEWS = {
    REQUEST: 'FETCH_NEWS_REQUEST',
    SUCCESS: 'FETCH_NEWS_SUCCESS',
    FAILURE: 'FETCH_NEWS_FAILURE',
};

export function fetchNews() {
    return {
        types: [FETCH_NEWS.REQUEST, FETCH_NEWS.SUCCESS, FETCH_NEWS.FAILURE],
        payload: {
            request: {
                url: '/news',
            },
        },
    };
}
