import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import HeaderLine from '../../common/HeaderLine';
import SearchBlock from '../../common/SearchBlock';
import Sparepart from './components/Sparepart';
import Related from './components/Related';
import Selector from './selectors';
import { fetchSpareparts, fetchSparepartsBySize } from './actions';

import './styles.scss';


function SearchResult(props) {
  const dispatch = useDispatch();
  const spareparts = useSelector(state => Selector(state, 'spareparts'));
  const related = useSelector(state => Selector(state, 'related'));

  const { location } = props;
  const search = location.search.substring(1);
  const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

  useEffect(() => {
    if(props.match.params.query && (params.type === 'OEM' || !params.type)) {
      dispatch(fetchSpareparts(props.match.params.query));
    }
    if(props.match.params.query && params.type === 'SIZE') {
      dispatch(fetchSparepartsBySize(props.match.params.query));
    }
  }, [dispatch, props.match.params, params.type]);
  console.log(spareparts);
  return (
    <div className="mainContainer relative">
      <HeaderLine redline />
      <HeaderLine title="Поиск деталей"/>
      <SearchBlock searchType={params.type} />
      <HeaderLine title="Результаты поиска"/>
      {spareparts && spareparts.map(item => <Sparepart key = {item.code + item.id} item ={item} />)}
      {!spareparts.length && <p>Автозапчастей не найдено</p>}
      <Related items={related} />
    </div>
  );
}

export default SearchResult;


