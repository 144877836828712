import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { verifyCode } from '../../features/Main/actions';
import Radio from '../Radio';
import Modal from '../Modal';
import './styles.scss';

export const SEARCH_OPTIONS = [{
  title: 'Поиск по артикулу или OEM детали',
  label: 'В поисковую строку введите номер артикула детали',
  value: 'OEM',
},{
  title: 'ПОИСК ПО РАЗМЕРАМ ГИЛЬЗЫ БЦ',
  label: 'Введите размер внутреннего диаметра гильзы БЦ',
  value: 'SIZE'
}, {
  title: 'Проверка подлинности продукции компании MOUSSON',
  label: 'Введите номер из-под стретч-полосы голограммы',
  value: 'CODE',
}];

function SearchBlock({ isMainPage = false, searchType = SEARCH_OPTIONS[0].value }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [searchMode, setSearchMode] = useState(searchType);
  const [verifyResult, setVerifyResult] = useState();

  const changeSearchModeHandler = useCallback((value) => setSearchMode(value), []);
  const searchInputHandler = useCallback((e) => setQuery(e.target.value), []);
  const onSearchClickHandler = useCallback(() => {
    if (searchMode === 'CODE' && query) {
      dispatch(verifyCode(query)).then(res => {
        setVerifyResult({ success: !!(res.payload.data && res.payload.data.id), data: res.payload.data });
      });
    } else if (searchMode === 'SIZE' && query) {
      history.push(`/search/${query}?type=${searchMode}`);
    } else if(searchMode === 'OEM' && query) {
      history.push(`/search/${query}`);
    }
  }, [dispatch, history, query, searchMode]);

  return (
    <>
      <div className={isMainPage ? 'mainSearchBlock' : 'pageSearchBlock' }>
        <div>
          <Radio options={SEARCH_OPTIONS} value={searchMode} onChange={changeSearchModeHandler}/>
        </div>
        <div>
          <input value={query} onChange={searchInputHandler} />
          <button className='default' onClick={onSearchClickHandler}>{searchMode !== 'CODE'  ? 'Поиск детали' : 'Проверка подлинности'}</button>
        </div>
      </div>

      {verifyResult && (
        <Modal
          open
          title={verifyResult.success
            ? (<>Введенный вами номер <span className="highlight">верен!</span></>)
            : (<span className="highlight">Введенный номер не прошел проверку на подлинность!</span>)
          }
          content={
            <>
              {verifyResult.success ? 'Данный номер подтверждает подлинность продукции компании MOUSSON' : 'Проверьте правильность набранного вами номера детали'}
              <br/>
              {
                verifyResult.success && <>
                  <strong>ВНИМАНИЕ! </strong>
                  Количество проверок подлинности продукта:
                  <strong>{` ${(verifyResult.data.totalCheck || 1)}`}</strong>
                </>
              }
            </>
          }
          onClose={() => setVerifyResult(undefined)}
        />
      )}
    </>
  );
}

SearchBlock.propTypes = {};

export default SearchBlock;


