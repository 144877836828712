import React from 'react';
import ReactPlayer from 'react-player';

export function VideoCell({item, viewType, goToArchive}) {
  const { article, name, brand, available, articleSearch } = item;
  return (
    <>
      {viewType !== 'variant2' && (
        <>
          <div className='attrRow'>
            <span>Наименование:</span>
            {name}
          </div>
          <div className='attrRow'>
            <span>Производитель:</span>
            {brand && brand.name}
          </div>
          <div className='attrRow'>
            <span>Артикул:</span>
            {article}
          </div>
          <div className='attrRow'>
            <span>Наличие на складе (шт.):</span>
            {available ? `${available.available > 5 ? 'более 5' : available.available}` : (<div className='highlight'>Нет в наличии</div>)}
          </div>
          <div className='pushRow'></div>
          <div>
            {goToArchive && <button className="default" onClick={goToArchive}>Архив заказов</button>}
          </div>
        </>
      )}
      {viewType === 'variant2' && (
        <>
          <div className="video">
            <ReactPlayer
              width="360px"
              height="202px"
              url={`/video/${articleSearch}`}
              playing
              autoplay
              muted
              controls
            />
          </div>
          <div>
            <div className='attrRow'>
              <span>Производитель:</span>
              {brand && brand.name}
            </div>
            <div className='attrRow'>
              <span>Наличие на складе (шт.):</span>
              {available ? `${available.available > 5 ? 'более 5' : available.available}` : (<div className='highlight'>Нет в наличии</div>)}
            </div>
          </div>
        </>
      )}
    </>);
}