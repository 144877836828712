const LOGIN_USER = {
    REQUEST: 'LOGIN_USER_REQUEST',
    SUCCESS: 'LOGIN_USER_SUCCESS',
    FAILURE: 'LOGIN_USER_FAILURE',
};

export function loginUser(data) {
    return {
        types: [LOGIN_USER.REQUEST, LOGIN_USER.SUCCESS, LOGIN_USER.FAILURE],
        payload: {
            request: {
                method: 'post',
                url: '/login',
                data,
            },
        },
    };
}

export function reducer(state = { isFetching: true, token: sessionStorage.token }, action) {
    switch (action.type) {
        case LOGIN_USER.SUCCESS:
            return {
                ...state,
                user: { ...action.payload.data, isFetching: false },
            };
        default: {
            return state;
        }
    }
}
