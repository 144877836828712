import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { useDispatch } from 'react-redux';
import { LightBox } from 'react-lightbox-pack'; // <--- Importing LightBox Pack
import  moment from 'moment';
import { sortBy, reverse } from 'lodash';
import 'react-multi-carousel/lib/styles.css';
import 'react-lightbox-pack/dist/index.css';

import HeaderLine from '../../../../common/HeaderLine';
import { images } from './constants';
import { fetchNews } from '../../ducks/fetchNews';
import './styles.scss';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const News = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] =  React.useState(false);
	const [sIndex, setSIndex] =  React.useState(0);
  const [news, setNews] = React.useState([]);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const onOpenNewsPackHandler = React.useCallback((e) => {
    e.stopPropagation();
    lightBoxHandler(true, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchNews()).then(res => setNews(reverse(sortBy(res.payload?.data || [], 'date'))));
  }, [dispatch]);

  return (
    <div className="newsMainBlock" id='news'>
      <HeaderLine type='h2' redline />
      <h2>Новости</h2>
      <div className="flex">
        <div className="newsPack">
          <div className="content">
            <ul className="ca-menu">
                <li>
                  <a onClick={onOpenNewsPackHandler}>
                    <span className="ca-icon">{'>'}</span>
                    <div className="ca-content">
                    <div className="botpac">
                        <h2 className="ca-main">Фирменная упаковка</h2>
                    </div>
                    </div>
                  </a>
                </li>
            </ul>
          </div>

          <LightBox
            state={toggle}
            event={lightBoxHandler}
            data={images}
            imageWidth="60vw"
            imageHeight="70vh"
            thumbnailHeight={50}
            thumbnailWidth={50}
            setImageIndex={setSIndex}
            imageIndex={sIndex}
          />
        </div>

        <Carousel responsive={responsive}>
          {news.map((item, key) => (
            <div className='card' key={key}>
              <div className='date'>{moment(item.date).format('DD MMMM YYYY')}</div>
              <p>{item.text}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default News;