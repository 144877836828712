import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


function Radio({ options, value, onChange }) {
  return options.map(item => (
    <div className="radio" key={item.value}>
      {item.title && <div className='title'>{ item.title }</div>}
      <fieldset className={ item.value === value ? 'row selected' : 'row'}>
        <div className="point" onClick={() => onChange(item.value)}></div>
        <label onClick={() => onChange(item.value)}>{ item.label }</label>
      </fieldset>
    </div>
  ));
}

Radio.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string})),
  onChange: PropTypes.func,
};

export default Radio;


