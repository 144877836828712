const UPDATE_BASKET = {
  REQUEST: 'UPDATE_BASKET_REQUEST',
  SUCCESS: 'UPDATE_BASKET_SUCCESS',
  FAILURE: 'UPDATE_BASKET_FAILURE',
};

export function updateBasket(item) {
  return {
      type: UPDATE_BASKET.SUCCESS,
      payload: item,
  };
}

export function reducer(state, action) {
  let item = {};
  let list = [];
  let isExist = false;

  switch (action.type) {
      case UPDATE_BASKET.REQUEST:
          return {
              ...state,
              isFetching: true,
          };
      case UPDATE_BASKET.SUCCESS:
          item = action.payload;
          isExist = state.list.find(el => el.id === item.id);
          console.log(isExist, item, state.list);
          list = [...state.list.map(el => el.id === item.id ? item : el), ...(!isExist ? [item] : [])];
          localStorage.setItem('basket', JSON.stringify(list));
          return {
              ...state,
              list,
              isFetching: false,
          };
      case UPDATE_BASKET.FAILURE:
          return {
              ...state,
              isFetching: false,
          };
      default: {
          return state;
      }
  }
}