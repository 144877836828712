import ConnectReducers from '../../helpers/ConnectReducers';
import { reducer as featchSpareparts } from './ducks/fetchSpareparts';
// import { reducer as addRecord } from './ducks/addRecord';

const initialState = {
    result: {
        origin: null, crosses: []
    },
    isFetching: true,
};

export default ConnectReducers([featchSpareparts], initialState);
