import React from 'react';
import moment from 'moment';
import './styles.scss';


const OrderRow = ({ code, totalCount, totalPrice, statusText, statusDate, details = [] }) => {
   
  return (
    <div className="order">
      <div className="orderRow">
        <div className="cell"><span className='title'>Код заказа: </span><span className='highlight'>{code.toUpperCase()}</span></div>
        <div className="cell"><span className='title'>Позиций: </span><span className='highlight'>{totalCount}</span></div>
        <div className="cell"><span className='title'>Сумма: </span><span className='highlight'>{totalPrice}</span></div>
        <div className="cell">
          <span className='title'>Статус: </span>
          <span className='highlight'>{statusText || 'Новый'}{statusDate && ` (${moment(statusDate).format('DD.MM.YY')})`}</span>
        </div>
      </div>
      {details.map(item => (
        <div key={item.id} className="detailRow">
          <div className="cellFully">{item.name}</div>
          <div className="cell">{item.brandName}</div>
          <div className="cell">{item.article}</div>
          <div className="cell">{item.count}</div>
          <div className="cell">{Math.round(item.price * item.count)}</div>
        </div>
      ))}
      
    </div>
  );
};

export default OrderRow;