import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import MainMenu from './common/Menu';
import Footer from './common/Footer';

import Main from './features/Main';
import Orders from './features/Orders';
import SearchResult from './features/Search';
import { Contacts } from './features/Contacts';
import { Basket } from './features/Basket';

import 'moment/locale/ru';

class App extends PureComponent {
    componentDidMount() {
        moment.locale('ru');
    }

    render() {
        const { location: { pathname } } = this.props;

        const path = pathname.slice(1);
        return (
          <>
            <MainMenu path={ path } />
            <Switch>
              <Route path="/" exact component={ Main } />
              <Route path="/search" exact component={ SearchResult } />
              <Route path="/search/:query" component={ SearchResult } />
              <Route path="/contacts" component={ Contacts } />
              <Route path="/orders" component={ Orders } />
              <Route path="/basket" component={ Basket } />
            </Switch>
            <Footer />
          </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: {
        // getUser: () => dispatch(fetchUser()),
        // logoutUser: () => dispatch(logoutUser()),
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
