import React from 'react';
import './styles.scss';


const HeaderLine = ({ title = '', type='h1', redline = false }) => (
  <div className={`line ${redline ? 'redline' : ''} ${type}`}>
    {type === 'h1' && <h1>{title}</h1>}
    {type === 'h2' && <h2>{title}</h2>}
    {type === 'h3' && <h3>{title}</h3>}
    {type === 'div' && title}
  </div>
);



export default HeaderLine;


