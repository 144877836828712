const FETCH_BASKET = {
    REQUEST: 'FETCH_BASKET_REQUEST',
    SUCCESS: 'FETCH_BASKET_SUCCESS',
    FAILURE: 'FETCH_BASKET_FAILURE',
};

export function fetchBasket(date) {
    return {
        type: FETCH_BASKET.SUCCESS,
        payload: {},
    };
}

export function reducer(state, action) {
    let list = [];
    switch (action.type) {
        case FETCH_BASKET.REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_BASKET.SUCCESS:
            try {
                list = JSON.parse(localStorage.getItem('basket'));
            } catch (e) {
                list=[];
            }
            return {
                ...state,
                list: list || [],
                isFetching: false,
            };
        case FETCH_BASKET.FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default: {
            return state;
        }
    }
}
