import React, { useEffect } from  'react';
// import PropTypes from 'prop-types';
import SearchBlock from '../../common/SearchBlock';
import About from './components/About';
import News from './components/News';
import RequestForm from './components/RequestForm';
import Slubs from './components/Slubs';

import './styles.scss';

const positionsBlock = {
  '#about': 1500,
  '#news': 2200,
  '#request': 1000,
};

const Main = (props) => {
  const { location } = props;

  useEffect(() => {
    positionsBlock[location.hash] && window.scrollTo({
      top: positionsBlock[location.hash],
      behavior: 'smooth'
  });
  }, [location.hash, location.search]);

  return (
    <>
      <div className='mainSlab'>
        <div className="mainContainer fullHeigh flex">
          <div className="waterMark"></div>
          <div>
            <div className='titleRow'>
              <h1>
                <span className='hilight'>Оптовая продажа</span>
                запчастей для ДВС
              </h1>
            </div>
            <SearchBlock isMainPage />
          </div>
        </div>
      </div>
      <div className="container">
        <Slubs />
        <RequestForm />
        <About />
        <News />
      </div>
    </>);
};

export default Main;


