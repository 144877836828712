const REMOVE_FROM = {
  REQUEST: 'REMOVE_FROM_REQUEST',
  SUCCESS: 'REMOVE_FROM_SUCCESS',
  FAILURE: 'REMOVE_FROM_FAILURE',
};

export function removeFromBasket(item) {
  return {
      type: REMOVE_FROM.SUCCESS,
      payload: item,
  };
}

export function reducer(state, action) {
  let item = {};
  let list = [];
  switch (action.type) {
      case REMOVE_FROM.REQUEST:
          return {
              ...state,
              isFetching: true,
          };
      case REMOVE_FROM.SUCCESS:
          item = action.payload;
          list = state.list.filter(el => el.code !== item.code);
          localStorage.setItem('basket', list);
          return {
              ...state,
              list,
              isFetching: false,
          };
      case REMOVE_FROM.FAILURE:
          return {
              ...state,
              isFetching: false,
          };
      default: {
          return state;
      }
  }
}