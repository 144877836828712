import React from 'react';
import Modal from '../../../../common/Modal';

import { PARTS } from './index';

export const Modals = ({ id, onClose }) => {
  let body = '';

  if(id === PARTS.PISTON) {
    body = (<div>
      <div className="part1"></div>
      <p>
        <span className="highlight">Поршни Mousson</span> производятся из легированных кремнием алюминиевых сплавов методом литья под
        давлением, что позволяет получить прочностные характеристики в точном соответствии с требованиями к
        оригинальным изделиям.
      </p>
      <p>
        Усиление отдельных участков и изделий в целом производится методами химического анодирования,
        применением технологии ALFIN (сращивания металлов) для усиления канавки компрессионного поршня, а
        также специальными покрытиями юбки поршней на основе сульфида молибдена.
      </p>
      <p>
        Вся продукция Mousson прошла испытания в зарубежных и Российских лабораториях, что подтверждается
        сертификатами соответствия стандартам качества.
      </p>
    </div>);
  }

  if(id === PARTS.SLEEVE) {
    body = (
      <div>
        <div className="part2"></div>
        <p>
          <span className="highlight">Гильзы цилиндров Mousson</span> изготавливаются методом центробежного литья из легированного кремнием и
          углеродом чугуна, что позволяет получить готовое изделие твердостью до 55 по HRC (как у лучших сталей),
          но обладающее необходимыми характеристиками для использования в блоках цилиндров ДВС. Внутренняя
          часть гильзы подвергается дополнительной закалке токами высокой частоты. Высокая точность соблюдения
          всех геометрических параметров гильз и система контроля качества производства позволяет получить
          нулевой коэффициент отказов (≤ 4 PPM)
        </p>
      </div>
    );
  }

  if(id === PARTS.PAD) {
    body = (
      <div>
        <div className="part3"></div>
        <p>
          <span className="highlight">Прокладки ГБЦ и комплекты прокладок ДВС Mousson</span>
           изготавливаются на предприятии, работающем на
          конвейер нескольких автопроизводителей. Фабрика использует немецкое оборудование. Все композитные
          материалы, уплотнения и герметики, поставляются из Европы. Формы для вырубки изготавливаются
          непосредственно на фабрике методом лазерной резки, что обеспечивает их высокую точность. Фабрика
          выпускает продукцию оригинального качества.
        </p>
      </div>
    );
  }

  if(id === PARTS.CHAIN) {
    body = (
      <div>
        <div className="part4"></div>
        <p>
          <span className="highlight">Цепи ГРМ Mousson</span>
           выполнены из легированных сталей с последующей закалкой на европейских
          технологических линиях. Проверка каждой цепи на разрыв и растяжение производится как на линии
          технологического процесса, так и в специальной лаборатории. Высочайшие стандарты качества
          производства позволяют быть поставщиком автопроизводителей большегрузных автомобилей, а в
          некоторых случаях превосходить прочностные характеристики других производителей оригинальных
          цепей. 
        </p>
      </div>
    );
  }

  if(id === PARTS.SHAFT) {
    body = (
      <div>
        <div className="part5"></div>
        <p>
          <span className="highlight">Коленвалы Mousson</span>
           являются лучшими образцами продукции для «афте маркет». Все геометрические
          размеры коленвалов соответствуют размерам производителей оригинальных деталей и полностью
          прямолинейны. Шейки коленвалов закалены. Большой опыт применения в двигателях в странах Европы
          и Ближнего Востока доказывает их надежность.
        </p>
      </div>
    );
  }

  return <Modal open title={''} content={body} onClose={onClose} className='modalSlub' />;
};