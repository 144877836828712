export const images = [
	{
		'id':  1,
		'image':  'static/Фотосессия MOUSSON/Mousson0047 new.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
	{
		'id':  2,
		'image':  'static/Фотосессия MOUSSON/Mousson0074 финал 1.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  3,
		'image':  'static/Фотосессия MOUSSON/Mousson0209 new.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  4,
		'image':  'static/Фотосессия MOUSSON/Mousson0255 финал.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  5,
		'image':  'static/Фотосессия MOUSSON/Mousson0274 new.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  6,
		'image':  'static/Фотосессия MOUSSON/Mousson0379 финал.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  7,
		'image':  'static/Фотосессия MOUSSON/Mousson0471 финал 2.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  8,
		'image':  'static/Фотосессия MOUSSON/Mousson0537 new вертикально.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  9,
		'image':  'static/Фотосессия MOUSSON/Mousson1598 new красный.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
  {
		'id':  10,
		'image':  'static/Фотосессия MOUSSON/Mousson1676 лого за моделью.jpg',
		'title':  'Фотосессия MOUSSON',
		'description': 'Фотосессия MOUSSON'
  },
];


export const news = [
	{ text: 'Уважаемые клиенты, у нас расширение ассортиментного перечня деталей ДВС, в а именно поступили на склад: коленвалы; муфты ГРМ; масляные насосы; клапана; шатуны на Корейские двигатели', date: '10 Января 2022'},
  { text: 'Уважаемые коллеги, мы представляем линейку деталей ДВС MOUSSON Original.', date: '01 Декабря 2021'},
	{ text: 'Покупая товары линейки MOUSSON Original, Вы можете быть уверены, что товар произведен на предприятии ОЕМ.', date: '01 Декабря 2021'},
	{ text: 'Все продукты линейки  MOUSSON Original защищены специальной голограммой и запущен сервис по проверке оригинальности продукта.', date: '01 Декабря 2021'},
  { text: `Уважаемые клиенты анонсируем расширение нашего ассортимента
	клапанами и шатунами на двигатели Корейских автомобилей.
	Поступление на склад ожидается в Декабре 2021г.`, date: '28 Ноября 2021'},
];
