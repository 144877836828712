import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Selector from '../../../Main/selectors';
import { updateBasket } from '../../../Basket/actions';
import HeaderLine from '../../../../common/HeaderLine';
import Modal from '../../../../common/Modal';
import {ImageCell} from './image_cell';
import {VideoCell} from './video_cell';
import './styles.scss';


const Sparepart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { item, goToArchive, onDelete, onAccept, accepted } = props;
  const { available, price } = item;
  const [count, setCount] = useState(item.count || 1);
  const [notAuthModal, toogleNotAuthModal] = useState(false);
  const user = useSelector(state => Selector(state, 'user'));
  
  const onChangeCountHandler = (e) => {
    const count = e.target.value;
    if (available.available < count) return;
    setCount(count);
    dispatch(updateBasket({ ...item,  count }));
  };
  const addBasketHandler = useCallback(() => { 
    user.id ? dispatch(updateBasket({ ...item,  count })) : toogleNotAuthModal(true);
  }, [dispatch, item, count, user]);

  const onAcceptHandler = useCallback((direction) => onAccept(item, direction), [item, onAccept]);

  const onDeleteHandler = useCallback(() => onDelete(item), [item, onDelete]);

  const goToRequest = useCallback(() => { toogleNotAuthModal(false); history.push(`/?r=${Math.random()}#request`); }, [history]);


  return (
    <>
      <div className='SparepartSlab'>
        <div className='colum'>
          <div className='subtitle'>
            <span>Артикул: {item.article}</span>
          </div>
          <div className='row'>
            <div className='colum'>
              <ImageCell viewType = 'variant2' item={item} />
            </div>
            <div className='colum'>
              <VideoCell viewType = 'variant2' item={item} goToArchive={goToArchive} />
            </div>
          </div>
        </div>
        <div className='colum'>
          <HeaderLine title="Стоимость" type='div'/>
          <div className='attrRow'>
            <span>Количество:</span>
            <input type='number' value={count} onChange={onChangeCountHandler} max={available ? available.available : 0} min={0}/>
            {'шт'}
          </div>
          <div className='attrRow'>
            <span>Цена оптовая:</span>
            {`${price} руб.`}
          </div>
          <HeaderLine />
          <div className='attrRow'>
            <span>Итоговая сумма:</span>
            <div className='highlight'>{ `${count * price} руб.` }</div>
          </div>
          <div className='pushRow'></div>
          <div>
            {onDelete && onAccept
              ? (
                <div className='row'>
                  <button
                    className={`default rightOffset ${accepted ? 'accent': ''}`}
                    onClick={() => accepted ? onAcceptHandler(false) : onAcceptHandler(true)}
                  >
                    {accepted ? 'Подтвержден' : 'Подтвердить'}
                  </button>
                  <button className="accent" onClick={onDeleteHandler}>Удалить</button>
                </div>
              )
              : (<button className="default" disabled={!(available && available.available)} onClick={addBasketHandler}>В корзину</button>)
            }
          </div>
        </div>
      </div>

      {notAuthModal &&
        <Modal
          open
          title={<>Для возможности покупок вам необходимо <span className="highlight">авторизоваться!</span></>}
          content={<>
            <p>Если вы являетесь нашим клиентом и у вас нет логина и пароля, свяжитесь с вашим персональным менеджером</p>
            <p>
              Если вы не являетесь нашим клиентом <a className="link" onClick={goToRequest}>отправьте заявку</a> 
            </p>

          </>}
          onClose={() => toogleNotAuthModal(false)}
        />
      }
    </>
  );
};


export default Sparepart;


