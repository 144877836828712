import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../../../common/Modal';
import { sendLetterRequest } from '../../actions';
import './styles.scss';

const RequestForm = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [sendSuccess, setSendSuccess] = useState(false);

  const changeHandler = useCallback((e) => setData({ ...data, [e.target.name]: e.target.value }), [data]);
  const submitHandler = useCallback(() => dispatch(sendLetterRequest(data)).then(() => {
    setData({});
    setSendSuccess(true);
  }), [data, dispatch]);

  return (
    <>
      <div className="requestForm" id='request'>
        <div className="title">Отправить <span className="highlight">заявку</span></div>
        <div className="content">
          <div className="grid">
            <div className="grid--span-6">
              <label>Наименование организации</label>
              <input type="text" placeholder="Наименование вашей компании" name='company' value={data.company || ''}  onChange={changeHandler} />
            </div>
            <div className="grid--span-6">
              <label>ИНН</label>
              <input type="text" placeholder="ИНН" name='inn' value={data.inn || ''}  onChange={changeHandler}/>
            </div>
          </div>

          <div className="grid">
            <div className="grid--span-6">
              <label>Адрес компании</label>
              <input type="text" placeholder="Адрес компании" name='address' value={data.address || ''}  onChange={changeHandler}/>
            </div>
            <div className="grid--span-6">
              <label className="required">Контактное лицо</label>
              <input type="text" placeholder="Контактное лицо" name='fio' value={data.fio || ''}  onChange={changeHandler} />
            </div>
          </div>

          <div className="grid">
            <div className="grid--span-6">
              <label className="required">Телефон</label>
              <input type="text" placeholder="+7 (xxx) xxx-xx-xx" name='phone' value={data.phone || ''}  onChange={changeHandler}/>
            </div>
            <div className="grid--span-6">
              <button className='default' disabled={!(data.phone && data.fio)} onClick={submitHandler}>Отправить</button>
            </div>
          </div>

        </div>
      </div>
      {sendSuccess && 
        <Modal
          open
          title={<>Письмо <span className="highlight">успешно отправлено!</span></>}
          content={<>
            <p>Ваша заявка принята. В ближайшее время с вами свяжется наш менеджер</p>
          </>}
          onClose={() => setSendSuccess(false)}
        />
      }
    </>
  );
};

export default RequestForm;