import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderLine from '../../common/HeaderLine';
import SearchBlock from '../../common/SearchBlock';
import OrderRow from './components/Order';
import Selector from './selectors';
import { fetchOrders } from './actions';


function Orders(props) {
  const dispatch = useDispatch();
  const orders = useSelector(state => Selector(state, 'orders'));

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    console.log(orders);
  }, []);

  return (
    <div className="mainContainer relative">
      <HeaderLine redline />
      <HeaderLine title="Поиск деталей"/>
      <SearchBlock />
      <HeaderLine title="Активные заказы"/>
      {orders && orders.length
        ? (
          <div>
            {orders.map(order => <OrderRow key={order.id} {...order} />)}
          </div>
        ) : (
          <div>У вас нет активных заказов</div>
        )}
    </div>
  );
}

export default Orders;


