/**
 * Client Configuration
 * @param axios   This parameter can be referenced to https://github.com/mzabriskie/axios#axioscreateconfig
 * @param options This parameter can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix
 */
import axios from 'axios';
import { API_URL } from '../../settings.js';

const backend = {
    client: axios.create({
        baseURL: API_URL,
        responseType: 'json',
        headers: sessionStorage.token ? { Authorization: 'Bearer ' + sessionStorage.token } : {},
        // validateStatus: () => true,
    }),
    options: {
        returnRejectedPromiseOnError: true,
        interceptors: {
            request: [
                (getState, config) => {
                    // if (getState().user.token || sessionStorage.token) {
                    //     config.headers['Authorization'] = 'Bearer ' + sessionStorage.token; //getState().user.token;
                    // }
                    return config;
                },
            ],
            response: [
                {
                    success: function ({ getState, dispatch, getSourceAction }, res) {
                        console.log(res); //contains information about request object
                        //...
                        return Promise.resolve(res);
                    },
                    error: function ({ getState, dispatch, getSourceAction }, error) {
                        return Promise.reject({ ...error, data: error.response ? error.response.data : {} });
                    },
                },
            ],
        },
    },
};

export default backend;
