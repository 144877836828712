const CLEAR = {
  REQUEST: 'CLEAR_BASKET_REQUEST',
  SUCCESS: 'CLEAR_BASKET_SUCCESS',
  FAILURE: 'CLEAR_BASKET_FAILURE',
};

export function clearBasket() {
  return {
      type: CLEAR.SUCCESS,
  };
}

export function reducer(state, action) {
  let list = [];
  switch (action.type) {
      case CLEAR.REQUEST:
          return {
              ...state,
              isFetching: true,
          };
      case CLEAR.SUCCESS:
          list = [];
          localStorage.setItem('basket', list);
          return {
              ...state,
              list,
              isFetching: false,
          };
      case CLEAR.FAILURE:
          return {
              ...state,
              isFetching: false,
          };
      default: {
          return state;
      }
  }
}