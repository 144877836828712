const SEND_LETTER_REQUEST = {
    REQUEST: 'SEND_LETTER_REQUEST_REQUEST',
    SUCCESS: 'SEND_LETTER_REQUEST_SUCCESS',
    FAILURE: 'SEND_LETTER_REQUEST_FAILURE',
};

export function sendLetterRequest(data) {
    return {
        types: [SEND_LETTER_REQUEST.REQUEST, SEND_LETTER_REQUEST.SUCCESS, SEND_LETTER_REQUEST.FAILURE],
        payload: {
            request: {
                method: 'post',
                url: '/letterRequest',
                data,
            },
        },
    };
}