const LOGOUT_USER = {
    REQUEST: 'LOGOUT_USER_REQUEST',
    SUCCESS: 'LOGOUT_USER_SUCCESS',
    FAILURE: 'LOGOUT_USER_FAILURE',
};

export function logoutUser() {
    sessionStorage.token = '';

    return {
        types: [LOGOUT_USER.REQUEST, LOGOUT_USER.SUCCESS, LOGOUT_USER.FAILURE],
        payload: {
            request: {
                method: 'get',
                url: '/logout',
            },
        },
    };
}

export function reducer(state = { isFetching: true, token: sessionStorage.token }, action) {
    switch (action.type) {
        case LOGOUT_USER.SUCCESS:
            return {
                ...state,
                user: { token: '', isFetching: false },
            };
        default: {
            return state;
        }
    }
}
