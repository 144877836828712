import React from 'react';

import HeaderLine from '../HeaderLine';
import './styles.scss';


const Footer = () => (
  <div className='footer'>
    <HeaderLine />
    <div className='row'>
      <div className='copyright'>
        Copyright ООО “ТЕХ ИМПОРТ АЗИЯ”
      </div>
      <div className="logo"></div>
    </div>
  </div>
);



export default Footer;


