import React, { FC } from 'react';

import HeaderLine from '../../common/HeaderLine';

import './styles.scss';


export const Contacts: FC<{}> = () => (
    <div className="mainContainer contacts relative">
      <HeaderLine redline />
      <div className='row'>
        <div className="content">
          <HeaderLine title="Контакты"/>
          <p>
            г. Москва ,123308, Хорошёво-Мнёвники район, Мневники, 1,строение 5
          </p>

          <HeaderLine title="Адрес для писем и документов"/>
          <p>
            123154, г. Москва, вн.тер.г. Муниципальный Округ Хорошево-Мневники, б-р Генерала Карбышева, дом 8, помещение 1/2
          </p>
          <HeaderLine title="Отдел продаж"/>
          <p>
            <div><strong>Телефон:</strong> +7(495) 122-22-92</div>
            <div><strong>E-mail:</strong> trade@mousson.parts</div>
          </p>
          <HeaderLine title="Бухгалтерия"/>
          <p>
            <div><strong>Телефон:</strong> +7(391) 292-15-10</div>
            <div><strong>E-mail:</strong> doc@mousson.parts</div>
          </p>
        </div>
        <div className='waterSign'></div>
      </div>
    </div>
  );


