import React, { useState }  from 'react';
import { useHistory } from 'react-router-dom';

import HeaderLine from '../../../../common/HeaderLine';
import './styles.scss';

const ITEMS_ON_PAGE = 4;


const Related = ({ items }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);

  const slabs = items.slice(page * ITEMS_ON_PAGE - ITEMS_ON_PAGE, page * ITEMS_ON_PAGE);

  return items.length ? (
    <>
        <HeaderLine title="Сопутствующие товары"/>
        <div className="RelatedRow">
        {page > 1 && <div className="arrowLeft" onClick={() => setPage(page - 1)}></div>}
          {slabs.map((item) => (
            <div className="RelatedSlab" key={item.id}>
              <div className="name" title={item.name}>{item.name}</div>
              <div
                className='image'
                style={ { backgroundImage: `url(/image/${item.articleSearch})`, cursor: 'pointer' } }
              >
              </div>
              <div className="article"><strong>Артикул:</strong> {item.article}</div>
              <button className="default" onClick={() => history.push(item.article)}>Перейти к товару</button>
            </div>
          ))}
          {items.length > page * ITEMS_ON_PAGE && <div className="arrowRight" onClick={() => setPage(page + 1)}></div>}
        </div>
    </>
  ) : null;
};

export default Related;

