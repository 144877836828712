import React, { useCallback, useState } from 'react';
import whaterMark from '../../../../assets/parts_logo.svg';
import { Modals } from './modal';
import './styles.scss';

export const PARTS = {
  PISTON: 'PISTON',
  SLEEVE: 'SLEEVE',
  PAD: 'PAD',
  CHAIN: 'CHAIN',
  SHAFT: 'SHAFT',
};

const Slubs = () => {
  const [idModal, setIdModal] = useState();

  const onClickSlabHandler = useCallback((id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIdModal(id);
  }, []);

  const closeModal = useCallback(() => setIdModal(null), []);
  
  return (
    <>
      <div className="container_24 mainSlub">
        <div className="content">
          <ul className="ca-menu">
            <li>
              <a href="#" onClick={onClickSlabHandler(PARTS.PISTON)}>
                <span className="ca-icon part1"><img  src={ whaterMark } /></span>
                <div className="ca-content">
                  <h2 className="ca-main">Поршни и кольца</h2>
                  <h3 className="ca-sub"></h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#" onClick={onClickSlabHandler(PARTS.SLEEVE)}>
                <span className="ca-icon part2"><img  src={ whaterMark } /></span>
                <div className="ca-content">
                  <h2 className="ca-main">Гильзы ДВС</h2>
                  <h3 className="ca-sub"></h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#" onClick={onClickSlabHandler(PARTS.PAD)}>
                <span className="ca-icon part3"><img  src={ whaterMark } /></span>
                <div className="ca-content">
                  <h2 className="ca-main">Прокладки ДВС</h2>
                  <h3 className="ca-sub"></h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#" onClick={onClickSlabHandler(PARTS.CHAIN)}>
                <span className="ca-icon part4"><img  src={ whaterMark } /></span>
                <div className="ca-content">
                  <h2 className="ca-main">Цепи ГРМ</h2>
                  <h3 className="ca-sub"></h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#" onClick={onClickSlabHandler(PARTS.SHAFT)}>
                <span className="ca-icon part5"><img  src={ whaterMark } /></span>
                <div className="ca-content">
                  <h2 className="ca-main">Коленчатые валы</h2>
                  <h3 className="ca-sub"></h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {idModal && <Modals id={idModal} onClose={closeModal} />}
    </>
  );
};

export default Slubs;