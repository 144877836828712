import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
// import axiosMiddleware from 'redux-axios';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { multiClientMiddleware } from 'redux-axios-middleware';
// import ConnectReducers from '../helpers/ConnectReducers';
import main from '../features/Main/reducers';
import orders from '../features/Orders/reducers';
import basket from '../features/Basket/reducers';
import search from '../features/Search/reducers';

import clients from './clients';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const ping = () => (next) => (action) => {
    console.log(`Тип события: ${action.type}, дополнительные данные события: ${action.payload}`);
    return next(action);
};

export default function configureStore(initialState) {
    const logger = createLogger();
    const history = createBrowserHistory();
    const store = createStore(
        combineReducers({
            router: connectRouter(history),
            main,
            orders,
            search,
            basket,
        }),
        initialState,
        composeEnhancers(applyMiddleware(thunk, logger, multiClientMiddleware(clients))),
    );
    return store;
}
