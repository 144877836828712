import ConnectReducers from '../../helpers/ConnectReducers';
import { reducer as fetchUserReducer } from './ducks/fetchUser';
import { reducer as loginUserReducer } from './ducks/loginUser';
import { reducer as logoutUserReducer } from './ducks/logoutUser';

const initialState = {
    user: {
        token: sessionStorage.token,
        isFetching: true,
    }
};

export default ConnectReducers([fetchUserReducer, loginUserReducer, logoutUserReducer], initialState);
