import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector,  useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import mainSelector from '../../features/Main/selectors';
import basketSelector from '../../features/Basket/selectors';
import { useBooleanFlag } from '../../hooks/use_boolean_flag';
import AuthModal from './components/AuthModal';
import { fetchUser } from '../../features/Main/ducks/fetchUser';
import { logoutUser } from '../../features/Main/ducks/logoutUser';
import './styles.scss';


function MainMenu(props) {
  const { path } = props;
  const user = useSelector(state => mainSelector(state, 'user'));
  const items = useSelector(state => basketSelector(state, 'basket'));
  const { value: open, setTrue: openAuthModal, setFalse: closeAuthModal } = useBooleanFlag();
  
  const history = useHistory();
  const dispatch = useDispatch();
  
  
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch, history, path]);

  const logOutHandler = useCallback(() => {
    dispatch(logoutUser())
      .then(() => {
        // eslint-disable-next-line no-restricted-globals
        location.href='/';
      });
  }, [dispatch]);

  return (
    <>
      <div className='mainMenu'>
        <div className='logoWrap'>
          <div className='logo' onClick={() => history.push('/')}></div>
          <div className='desc'>
            <span>Дистрибьютор в России</span>
            <br/>
            <span>ООО Тех Импорт Азия</span>
          </div>
        </div>
        <div className='menuWrap'>
          {user.id && !user.isFetching && (
            <>
              <Link to='/orders' className={`linkItem ${'orders' === path ? 'active' : ''}`}>Заказы</Link>
              <Link to='/contacts' className={`linkItem ${'contacts' === path ? 'active' : ''}`}>Контакты</Link>
              <Link to='/basket'>
                <div className={`imageItem basket ${'basket' === path ? 'active' : ''}`}>
                  {items.length > 0 && (<div className='count'>{items.length}</div>)}
                </div>
              </Link>
              <div className='imageItem exit' onClick={logOutHandler}></div>
            </>
          )}
          {!user.id && (
            <>
              <Link to="/" className={`linkItem ${'' === path ? 'active' : ''}`}>Главная</Link>
              <Link to="/#about" className='linkItem'>О компании</Link>
              <Link to="/#news" className='linkItem'>Новости</Link>
              <Link to='/contacts' className={`linkItem ${'contacts' === path ? 'active' : ''}`}>Контакты</Link>
              <div className='imageItem entrance' onClick={openAuthModal}></div>
            </>
          )}

        </div>
      </div>
      <AuthModal open={open} onClose={closeAuthModal} />
    </>
  );
}

MainMenu.propTypes = {
  path: PropTypes.string.isRequired
};

export default MainMenu;


