import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


function Modal({ open, title, content, onClose, className = '' }) {
  return open && (
    <div className={`modal ${className}`} style={{ top: window.scrollY + 200 }}>
      <div className='shadow'></div>
      <div className='body'>
        <div className='closeIco' onClick={onClose}></div>
        <div className='title'>{title}</div>
        <div className='content'>{content}</div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string})),
  onChange: PropTypes.func,
};

export default Modal;


